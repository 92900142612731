$main-blue: #222d44;
$main-orange: #fc432d;
$font-color-blue: #2fa2f8;
$fm-red-orange: #ff5b2d;
$fm-orange: #f1882a;
$fm-yellow: #efb508;
$fm-green: #006d2e;
$fm-green2: #11a951;
$fm-green3: #6dc300;
$fm-yellow-green: #a4bf00;
$fm-light-aqua: #00c2ff;
$fm-aqua: #0097ff;
$fm-blue: #0068e2;
$fm-dark-blue: #3700d3;
$fm-turquoise: #00c8b0;
$fm-purple: #9b51e0;
$fm-pink: #f12ab9;
$fm-magenta: #f12a66;
$fm-gray1: #4f4f4f;
$fm-gray2: #828282;
$fm-gray3: #bdbdbd;
$fm-gray4: #e0e0e0;
$fm-gray5: #f5f6fa;
$disabled-color: rgba(130,130,130,0.3);
