@font-face {
  font-family: 'GraphikLCG';
  src: url('/assets/fonts/GraphikLCG-Semibold.eot');
  src: url('/assets/fonts/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/GraphikLCG-Semibold.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Semibold.ttf') format('truetype'),
    url('/assets/fonts/GraphikLCG-Semibold.svg#GraphikLCG-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikLCG';
  src: url('/assets/fonts/GraphikLCG-Regular.eot');
  src: url('/assets/fonts/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/GraphikLCG-Regular.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Regular.ttf') format('truetype'),
    url('/assets/fonts/GraphikLCG-Regular.svg#GraphikLCG-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikLCG';
  src: url('/assets/fonts/GraphikLCG-Bold.eot');
  src: url('/assets/fonts/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/GraphikLCG-Bold.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Bold.ttf') format('truetype'),
    url('/assets/fonts/GraphikLCG-Bold.svg#GraphikLCG-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikLCG';
  src: url('/assets/fonts/GraphikLCG-Thin.eot');
  src: url('/assets/fonts/GraphikLCG-Thin.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/GraphikLCG-Thin.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Thin.ttf') format('truetype'),
    url('/assets/fonts/GraphikLCG-Thin.svg#GraphikLCG-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikLCG';
  src: url('/assets/fonts/GraphikLCG-Medium.eot');
  src: url('/assets/fonts/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/GraphikLCG-Medium.woff2') format('woff2'),
    url('/assets/fonts/GraphikLCG-Medium.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Medium.ttf') format('truetype'),
    url('/assets/fonts/GraphikLCG-Medium.svg#GraphikLCG-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
